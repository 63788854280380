import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import NwHelmet from "../components/NwHelmet"
import PodcastFrame from "../components/PodcastFrame"
import Header from "../components/HeaderSub"
import podcastLogoColor from "../images/podcast/podcast-logo-color.svg"
import PdfIcon from "../components/icons/PdfIcon"
import ArrowBandaid from "../components/icons/ArrowBandaid"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

import getSlugFromString from "../utils/get-slug-from-string"

import transcriptIcon from "../images/transcript-icon.svg"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: calc(100% - 3rem);
  overflow: auto;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  > div {
    width: 100%;
  }

  > * { z-index: 1; }

  :before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 20vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  }
`

const Heading = styled.h1`
  margin: 1em;
  font: 1.376rem/1.4 ${props => props.theme.headingFont};
  color: white;
  flex-grow: 1;
`

const StyledPodcastFrame = styled(PodcastFrame)`
  max-width: 800px;
  margin: 0 auto;
  opacity: 0.9;
  height: auto;
  padding: 1em;

  @media (min-width: 800px) {
    margin-top: 3rem;
  }
`

const DownloadTranscript = styled.a`
  display: flex;
  align-self: center;
  align-items: center;
  max-width: 500px;
  margin-bottom: 1em;
  padding: 1em;
  border: 1px solid rgba(255, 255, 255, 0);
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-size: 13.3333px;
  line-height: 1.3;
  background: rgba(0, 0, 0, 0.4);
  transition: all .3s ease-in-out;

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
  }

  :hover, :focus {
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-shadow: 0 0.2em 0.5em rgba(0, 0, 0, 0.4);
    transform: translateY(-3px);
    color: white;
  }

  img {
    margin-right: 0.8em;
    vertical-align: top;
  }

  span {
    margin: auto 0.8em;
    padding-right: 0.8em;
    border-right: 1px solid;
  }
`

const Nav = styled.nav`
  display: flex;
  background: rgba(0, 0, 0, 0.7);

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
  }
`

const ButtonPrevNext = styled(Link)`
  display: inline-block;
  width: 50%;
  padding: 1em;
  text-decoration: none;
  color: #aaa;
  transition: all .3s ease-in-out;
  flex-basis: 50%;
  flex-grow: 1;

  div {
    font-weight: bold;

    span {
      display: inline-block;
      vertical-align: top;
      text-decoration: inherit;
      font-weight: normal;
      font-size: 0.85em;
    }
  }

  p {
    font-size: 1rem;
    margin: 0 0 0.2em;
    font-weight: 600;

    @media (min-width: 480px) {
      margin-left: 1.2em;
      margin-right: 1.2em;
    }
  }

  &.prev {
    text-align: left;
  }

  &.next {
    text-align: right;
  }

  :hover, :focus {
    transform: translateY(-3px);
    color: white;

    p {
      text-decoration: underline;
    }

    svg g { fill: white; }
  }
`

const StyledArrowBandaid = styled(ArrowBandaid)`
  width: 1.2rem;
  height: 1.25rem;
  g { fill: #aaa; }
`

const StyledPodcastLogo = styled.img`
  width: 4.5rem;
`

export default function PodcastTemplate({
  data: { podcastYaml: { title, image, transcript } },
  pageContext: { lang, pagePath, pod_id, prevPod, nextPod },
}) {
  const intl = useIntl()
  const imageFileType = image ?
    (image.toLowerCase().endsWith(".jpg") ? "jpeg" : image.split(".").slice(-1)[0]).toLowerCase() :
    null

  return (
    <Wrapper>
      <NwHelmet
        lang={lang}
        path={pagePath}
        title={`${title} – ${intl.formatMessage({ id: "podcast" })}`}
        image={image ? {
          url: `${image}?nf_resize=smartcrop&w=1200&h=630`,
          type: `image/${imageFileType}`,
          width: 1200,
          height: 630,
        } : null}
      />
      <Header
        lang={lang}
        title={<StyledPodcastLogo src={podcastLogoColor} alt={intl.formatMessage({ id: "podcast" })} />}
        headingLevel="h2"
        room={7}
      />
      <Content style={{ backgroundImage: image ? `url(${image}?nf_resize=fit&w=1500)` : "none" }}>
        <Heading>{title}</Heading>
        <StyledPodcastFrame lang={lang} id={pod_id} />
        {transcript &&
          <DownloadTranscript href={transcript}>
            <span>
              <img
                src={transcriptIcon}
                alt={`${intl.formatMessage({ id: "icon" })} ${intl.formatMessage({ id: "transcript" }).toLowerCase()} `}
              />
              {intl.formatMessage({ id: "download" })} {intl.formatMessage({ id: "transcript" }).toLowerCase()}
            </span>
            <PdfIcon />
          </DownloadTranscript>
        }
        <Nav>
          {prevPod &&
            <ButtonPrevNext
              className="prev"
              to={`/${lang}/podcast/${getSlugFromString(prevPod)}`}
            >
              <div>
                <StyledArrowBandaid
                  dir="left"
                  />
                <span>{intl.formatMessage({ id: "previous" })} </span>
              </div>
              <p>{prevPod}</p>
            </ButtonPrevNext>
          }
          {nextPod &&
            <ButtonPrevNext
              className="next"
              to={`/${lang}/podcast/${getSlugFromString(nextPod)}`}
            >
              <div>
                <span>{intl.formatMessage({ id: "nextNasta" })} </span>
                <StyledArrowBandaid
                  dir="right"
                />
              </div>
              <p>{nextPod}</p>
            </ButtonPrevNext>
          }
        </Nav>
      </Content>
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query($pod_id: String) {
    podcastYaml(pod_id: { eq: $pod_id }) {
      title
      transcript
      image
    }
  }
`
