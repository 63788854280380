import React from "react"
import styled from "styled-components"

import CookiesDisabledMessage from "./CookiesDisabledMessage"

import { useStore } from "../hooks/store"

const Iframe = styled.iframe`
  display: block;
`
/*
Widget api & parameters
https://developers.soundcloud.com/docs/api/html5-widget

auto_play
buying
liking
download
sharing
show_artwork
show_comments
show_playcount
show_user
hide_related
visual
*/
const PodcastFrame = ({ lang, id, ...props }) => {
  if (!id) {
    return null
  }

  const [store] = useStore()
  const isAcast = id.includes("acast.com")
  const src = isAcast
    ? `${id.replace("shows.acast.com", "embed.acast.com").replace("/episodes", "").split("?")[0]}?theme=light&cover=false`
    : `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&color=%233A3A3A&show_artwork=false&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false&download=true&sharing=true`

  return (
    (isAcast || store.cookies.soundcloud) ?
      <Iframe
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        width="100%"
        height="450"
        src={src}
        {...props}
      />
    :
      <CookiesDisabledMessage lang={lang} provider="soundcloud" />
  )
}

export default PodcastFrame
